var Backbone = require('backbone');
var _ = require('underscore');

var Base = require('front/components/Base/Base');

require('./CommonVideoPopup.less');

var CommonVideoPlayer = require('front/components/CommonVideoPlayer/CommonVideoPlayer.js');

module.exports = Base.extend({
    template: require('./CommonVideoPopup.jinja'),

    el: '.CommonVideoPopup',

    events: {
        'click .CommonVideoPopup-close': 'closePopup',
    },

    VIEWS_TO_REGISTER: {
        'CommonVideoPlayer': {
            selector: '.CommonVideoPlayer', viewConstructor: CommonVideoPlayer
        }
    },

    initialize: function (options) {
        _.bindAll(this, 'openPopup', 'loadVideo', 'triggerPopupShown', 'triggerPopupHidded');

        this.video = this.$('video');

        // Добавляем обработку курсора для iframe
        if (app.settings.isDesktop) {
            this.$('.CommonVideoPopup-inner').on({
                mouseenter: function() {
                    $('.Cursor').css('opacity', 0);
                },
                mouseleave: function() {
                    $('.Cursor').css('opacity', 1);
                }
            });
        }

        app.vent.on('openPopup', this.openPopup);

        Base.prototype.initialize.call(this, options);
    },

    openPopup: function () {
        // this.videoSrc = this.$('.CommonVideoPopup-inner').data('video-popup');

        // this.loadVideo();
        // this.video[0].play();
        this.$el.addClass('isOpen');
        this.triggerPopupShown();
        // app.vent.trigger('disabledHorizontalScroll');

        this.$el.on('mousemove', function () {
            if (this.$('.CommonVideoPopup-inner:hover').length == false) {
                $('.Cursor').removeClass('isPause isPlay');
            }
        }.bind(this));
    },

    loadVideo: function () {
        if (this.loadInitiated) return;

        this.$('source').attr('src', this.videoSrc);
        this.video[0].load();

        this.loadInitiated = true;
    },

    closePopup: function (e) {
        e.preventDefault();

        this.$el.removeClass('isOpen');

        // Останавливаем видео через postMessage API
        var iframe = this.$('iframe')[0];
        if (iframe) {
            iframe.contentWindow.postMessage('{"method":"pause"}', '*');
        }

        this.triggerPopupHidded();

        clearTimeout(timer);

        var timer = setTimeout(function () {
            $('.Cursor').removeClass('isPause isPlay');
        }, 100);
    },

    triggerPopupShown: function () {
        this.savedScroll = app.els.$window.scrollTop();

        app.vent.trigger('PopupShown');
        app.els.$window.scrollTop(0);
    },

    triggerPopupHidded: function () {
        app.vent.trigger('PopupHidded');
        app.els.$window.scrollTop(this.savedScroll);
    }
});